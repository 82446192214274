.carousel-container,
.video {
  position: relative;
  width: 100%;
  max-width: 1200px; 
  height: 700px; 
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}


.carousel-slide {
  display: flex;
  transition: transform 0.8s ease-in-out;
  width: 100%;
}


.carousel-image {
  min-width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
  transition: opacity 0.5s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}


.carousel-image.active {
  opacity: 1;
}


.carousel-image:not(.active) {
  opacity: 0;
}

.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.8);
  color: #333;
  border: none;
  padding: 12px;
  cursor: pointer;
  border-radius: 50%;
  font-size: 24px;
  transition: background 0.3s ease, transform 0.3s ease;
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


.carousel-control:hover {
  background: rgba(255, 255, 255, 1);
  transform: translateY(-50%) scale(1.1);
}


.carousel-control.left {
  left: 20px;
}

.carousel-control.right {
  right: 20px;
}

button {
  z-index: 10;
  outline: none;
}


.btn-prevnext {
  background-image: linear-gradient(to right, #979797 0%, #b0b0b0 51%, #979797 100%);
  margin: 10px;
  padding: 8px 18px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}


.btn-prevnext:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}


.btn-close {
  background-image: linear-gradient(to right, #757F9A 0%, #D7DDE8 51%, #757F9A 100%);
  margin: 10px;
  padding: 5px 15px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}

.btn-close:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}

@media (max-width: 1024px) {
  .carousel-container,
  .video {
    height: 60vh;
  }

  .carousel-control {
    padding: 10px;
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .carousel-container,
  .video {
    height: 50vh; 
  }

  .carousel-control {
    padding: 8px;
    font-size: 18px;
  }

  .btn-prevnext,
  .btn-close {
    margin: 8px;
    padding: 6px 14px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .carousel-container,
  .video {
    height: 40vh; 
  }

  .carousel-control {
    padding: 6px;
    font-size: 16px;
  }

  .btn-prevnext,
  .btn-close {
    margin: 6px;
    padding: 4px 12px;
    font-size: 12px;
  }
}

.carousel-container {
  perspective: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px;
  height: auto;
}

.carousel {
  width: 60%;
  height: 300px;
  position: relative;
  transform-style: preserve-3d;
  animation: rotate 35s infinite linear;
}

.carousel-item {
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 10px;
  overflow: hidden;
}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.carousel-item .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(156, 156, 156, 0.2);
  transition: background 0.5s ease;
  z-index: 1; 
}


.carousel-item:hover .overlay {
  background: rgba(240, 24, 24, 0); 
}


.carousel-item:nth-child(1) {
  transform: rotateY(0deg) translateZ(250px);
}
.carousel-item:nth-child(2) {
  transform: rotateY(90deg) translateZ(250px);
}
.carousel-item:nth-child(3) {
  transform: rotateY(180deg) translateZ(250px);
}
.carousel-item:nth-child(4) {
  transform: rotateY(270deg) translateZ(250px);
}

@keyframes rotate {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

@media (max-width: 768px) {
  .carousel {
    height: 200px; 
  }
  .carousel-item {
    transform: rotateY(0deg) translateZ(200px);
  }
}

@media (max-width: 480px) {
  .carousel {
    height: 150px;
    width: 200px;
  }
  .carousel-item {
    transform: rotateY(0deg) translateZ(50px); 
  }
}